export class PostalCodeModel {
  public readonly value: string

  constructor(postalCode: string) {
    const hyphen = /[-˗ᅳ᭸‐‑‒–—―⁃⁻−▬─━➖ーㅡ﹘﹣－ｰ𐄐𐆑 ]/g
    const value = postalCode
      .replace(hyphen, '')
      .replace(/(\s)+/g, '')
      .replace(
        /[０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
    if (value.length !== 7)
      throw new Error(`invalid postal code: ${value}`)
    this.value = value
  }
  public format(): string {
    return `${this.value.slice(0, 3)}-${this.value.slice(3)}`
  }
  public static format(postalCode: string): string {
    const postalCodeModel = new PostalCodeModel(postalCode)
    return postalCodeModel.format()
  }
  public toJSON(): string {
    return this.value
  }
  public toString() {
    return this.value
  }
}
