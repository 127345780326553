import { PostalCodeModel } from '~/models/postalCode'
import { PrefectureCodeModel } from '~/models/prefectureCode'

export type AddressModelJSON = {
  postalCode: string
  prefectureCode: number
  city: string
  address1: string
  address2: string
  building: string
}

type ConstructorArgs = {
  postalCode: PostalCodeModel,
  prefectureCode: PrefectureCodeModel,
  city: string
  address1: string
  address2: string
  building: string
}

type Update = (args:{
  postalCode: PostalCodeModel,
  prefectureCode: PrefectureCodeModel,
  city: string
  address1: string
  address2: string
  building: string
}) => AddressModel

export class AddressModel {
  public readonly postalCode: PostalCodeModel
  public readonly prefectureCode: PrefectureCodeModel
  public readonly city: string
  public readonly address1: string
  public readonly address2: string
  public readonly building: string

  constructor(args: ConstructorArgs) {
    this.postalCode = args.postalCode
    this.prefectureCode = args.prefectureCode
    this.city = args.city.trim().replace(/(\s)+/g, ' ')
    this.address1 = args.address1.trim().replace(/(\s)+/g, ' ')
    this.address2 = args.address2.trim().replace(/(\s)+/g, ' ')
    this.building = args.building.trim().replace(/(\s)+/g, ' ')
  }

  public toJSON(): AddressModelJSON {
    return {
      postalCode: this.postalCode.toJSON(),
      prefectureCode: this.prefectureCode.toJSON(),
      city: this.city,
      address1: this.address1,
      address2: this.address2,
      building: this.building,
    }
  }

  public static fromJSON(json: AddressModelJSON): AddressModel {
    return new AddressModel({
      postalCode: new PostalCodeModel(json.postalCode),
      prefectureCode: new PrefectureCodeModel(json.prefectureCode),
      city: json.city,
      address1: json.address1,
      address2: json.address2,
      building: json.building,
    })
  }
}
